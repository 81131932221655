.login-form-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-image {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

.login-image img {
  width: 250px;
}

.login-form-card {
  width: 450px;
  padding: 0px;
  border: 0px;
}

.login-submit-button {
  width: 100%;
}

.title-container {
}

.dash-user-card {
  border-radius: 5px;
  box-shadow: 1px 1px 1px 0px rgba(97, 95, 145, 0.3);
}
.dash-user-card .ant-card-head {
  background: rgba(97, 95, 145, 0.5);
  color: rgba(60, 59, 84, 1);
}
.user-card-container {
  padding: 30px;
  background: #f6f6f6;
}

.outlet-card-container {
  padding: 30px;
  background: #f0f0f1;
}

.card-triple-image-container-body {
}
.table-container {
  padding: 30px;
  background-color: #f2f2f2;
}

.title-search-conatiner {
  padding: 30px 30px 0 30px;
}

.map-container {
  padding: 0 30px;
}

.map-container .markercluster-map {
  box-shadow: 1px 1px 1px 1px #2222;
}

.map-card .ant-card-body {
  padding: 0px;
}

.search-input input {
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid #43425d;
  background: white;
}

.search-input input:hover {
  box-shadow: none;
  border-radius: 0px;
}

.search-input input:focus {
  box-shadow: none;
  border-radius: 0px;
}

.search-input .ant-input:not(:first-child) {
  padding-left: 50px;
  font-size: 14px;
}
.search-input .ant-input-prefix {
  font-weight: 700;
}

.marker-card-side-line .marker-card-side-line-tag .line-tag {
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
}

.marker-card-side-line .marker-card-side-line-info .line-info {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

.leaflet-popup-content {
  margin: 0px;
}

.leaflet-popup-content-wrapper {
  border-radius: 5px;
  padding: 0px;
  margin: 0px;
}

.route-search-popup .leaflet-popup-content-wrapper {
  border-radius: 5px;
}

.merchandiser-drawer-left-side-line {
  padding-bottom: 15px;
}

.title-datepicker-conatiner {
  padding-bottom: 15px;
}

.outlet-drawer-left-side-line {
  padding-bottom: 5px;
}
.call-card {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgba(97, 95, 145, 0.3);
  box-shadow: 0px 0px 3px 0px rgba(97, 95, 145, 0.3);
  background: white;
}

.main-call-card {
  border-radius: 8px;
  border: 1px solid #efefef;

  /* background: rgba(60, 59, 84, 0); */
  background: white;
  min-height: 440px;
  padding: 16px;
}

.card-body-address-container {
}

.card-body-image-container {
  padding: 4px 4px 0px 4px;
  box-shadow: none;
}

.card-body-image-container:hover {
  box-shadow: none;
}

.ant-card-actions {
  background: none;
}

.ant-card-type-inner .ant-card-head {
  background-color: rgba(60, 59, 84, 1);
  color: white;
}
.ant-card-body {
  padding: 24px;
}

.call-card-line {
  padding-bottom: 10px;
}

.call-card-line .call-card-line-tag .line-tag {
  line-height: 1;
  color: rgba(60, 59, 84, 0.9);
}

.call-card-line .call-card-line-info .line-info {
  line-height: 1;
  color: rgba(60, 59, 84, 1);
}

.name icon {
  padding-right: 10px;
}

.name {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 6px;
  align-items: center;
}

.number {
  padding-right: 10px;
}

.upload-date i {
  padding: 5px;
}

.upload-date {
  font-size: 14px;
  font-weight: 400;
  color: #096ab9;
}

.upload-time i {
  padding: 5px;
  color: #096ab9;
}

.upload-time {
  font-size: 14px;
  font-weight: 400;
  color: #096ab9;
  margin-right: 14px;
}

.call-date i {
  padding: 5px;
}

.call-date {
  font-size: 14px;
  font-weight: 400;
  margin-right: 14px;
  color: #138f0d;
}

.call-time i {
  padding: 5px;
}

.call-time {
  font-size: 14px;
  font-weight: 400;
  margin-right: 14px;
  color: #138f0d;
}

.line-tag {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
}

.line-info {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
}

.ant-dropdown-menu-item:hover {
  background: rgba(97, 95, 145, 0.3);
  color: #3c3b54;
}
.ant-dropdown-menu-item-selected {
  background: rgba(97, 95, 145, 0.3);
  color: #3c3b54;
}

.submit-button {
  background: #e4418a;
  border: 1px solid #d12673;
  outline: none;
  color: white;
  font-weight: 500;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.3);
  margin-right: 5px;
}

.submit-button a svg {
  top: 23%;
  left: 28%;
}

.submit-button:hover {
  background: rgba(97, 95, 145, 0.3);
  border: none;
  outline: none;
  color: #3c3b54;
  font-weight: 500;
}

.submit-button:active {
  background: rgba(97, 95, 145, 0.3);
  border: none;
  outline: none;
  color: #3c3b54;
  font-weight: 500;
}

.submit-button:focus {
  background: rgba(97, 95, 145, 0.3);
  border: none;
  outline: none;
  color: #3c3b54;
  font-weight: 500;
}

.filter-container {
  padding: 8px;
}

.filter-collapse-button {
  width: 100%;
  /* background: rgba(217, 130, 159, 0.272); */
}

.filter-collapse-button > span {
  font-size: 14px;
  color: black;
  font-weight: 600;
}

.call-filter-ex-icon {
  color: #d12673;
  margin-left: 12px;
}

.shadow-container {
  background: rgba(217, 130, 159, 0.272);
  border-radius: 8px;
  border: 2px solid lightgray;
}

.filter {
  padding: 10px 16px 10px 16px;
}

.elastic-select .ant-select-selection:hover {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.elastic-select .ant-select-selection:active {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.elastic-select .ant-select-selection:focus {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.elastic-select:active {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.responsive-image {
  position: relative;
  max-width: 100%;
  margin-bottom: 5px;
  border-radius: 50%;
}

.responsive-image__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  box-shadow: 1px 1px 1px 0px rgba(97, 95, 145, 0.1);
  border-radius: 5px;
}

.responsive-image__image:hover {
  border: 1px solid #e4418a;
}

.profile-image {
  position: relative;
  max-width: 100%;
  margin-bottom: 5px;
  border-radius: 50%;
}
.profile-image__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  box-shadow: 1px 1px 1px 0px rgba(97, 95, 145, 0.1);
  border-radius: 50%;
}

.latest-call-item .ant-list-item {
  padding-bottom: 6px;
}

.pre-call-card {
  background: rgba(190, 195, 214, 0.171);
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 3px 0px rgba(97, 95, 145, 0.3);
  color: rgba(0, 0, 0, 01);
  font-weight: 500;
  padding: 30px;
}

.pre-merchandiser-card {
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 3px 0px rgba(97, 95, 145, 0.3);
  color: rgba(0, 0, 0, 01);
  font-weight: 500;
  padding: 30px;
}

.agent-card {
  background: rgba(146, 144, 144, 0.5);
}

.merchant-card {
  background: rgba(255, 166, 0, 0.5);
}

.leaflet-marker-icon {
  opacity: 1;
}

.leaflet-div-icon {
  animation: shadow-pulse 0.8s infinite;
  background: none;
  border: none;
}

.profile-container {
  padding: 30px;
}

.profile-basic-info-row {
  padding-bottom: 0px;
}

.profile-basic-info-card {
  box-shadow: 0px 0px 1px 0px rgba(97, 95, 145, 0.3);
  font-weight: 500;
  color: rgba(0, 0, 0, 0.616);
  border-radius: 5px;
}
.profile-selfie-card {
  box-shadow: 0px 0px 1px 0px rgba(97, 95, 145, 0.3);
  font-weight: 500;
  color: rgba(0, 0, 0, 0.616);
  border-radius: 5px;
}

.profile-basic-info-line {
  line-height: 1.5;
}

.info-line {
  text-align: center;
}

.profile-image-line {
  text-align: center;
  padding: 10px 0 10px 0;
}

.profile-image {
  padding: 15px;
}

.profile-selfie-card {
  padding-top: 16px;
}

.form-date-elasticsearch {
  padding: 30px 30px 50px 30px;
}

.form-date-range-elasticsearch {
  padding: 30px 30px 50px 0px;
}

.ant-select-selection:focus {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.ant-select-selection {
  box-shadow: none;
}

.ant-select-selection:hover {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.ant-select-selection:active {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.ant-select-selection:focus {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.ant-calendar-picker :focus {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.ant-calendar-picker input:hover {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.ant-calendar-picker input:active {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.ant-calendar-picker input:focus {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.ant-select-dropdown-menu li:hover {
  background: rgba(97, 95, 145, 0.3);
}

.alert-container {
  padding: 50px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: rgba(97, 95, 145, 0.8);
}

.report-icon {
  color: rgb(0, 165, 0);
  margin-right: 5px;
  font-size: 25px;
}

.modal-icon {
  color: rgb(5, 5, 5);
  font-size: 18px;
}

.outlet-name {
  margin-right: 9px;
  color: rgb(255, 5, 180);
}
.partner-type {
  margin-right: 6px;
  color: rgba(255, 5, 180, 1);
}
.outlet-number {
  margin-right: 14px;
  color: rgba(255, 5, 180, 1);
}

.outlet-region {
  margin-right: 10px;
  color: transparent;
  text-shadow: 0 0 0 rgba(8, 105, 185, 0.8);
}

.outlet-area {
  margin-right: 10px;
  color: transparent;
  text-shadow: 0 0 0 rgba(8, 105, 185, 0.6);
}

.outlet-territory {
  margin-right: 10px;
  color: transparent;
  text-shadow: 0 0 0 rgba(8, 105, 185, 0.4);
}

.outlet-distribution-house {
  margin-right: 10px;
  color: transparent;
  text-shadow: 0 0 0 rgba(8, 105, 185, 0.2);
}

.outlet-bmcc {
  margin-right: 10px;
  color: transparent;
  text-shadow: 0 0 0 rgba(8, 105, 185, 0.1);
}

.outlet-address {
  margin-right: 6px;
  color: #096ab9;
}
.outlet-qrcode {
  margin-left: 5px;
  margin-right: 12px;
  color: rgb(2, 2, 2);
}

.outlet-lat {
  margin-right: 10px;
  color: rgba(255, 0, 0, 1);
}

.outlet-lng {
  margin-right: 10px;
  color: rgba(255, 0, 0, 1);
}
.user-name {
  margin-right: 10px;
  color: rgb(152, 2, 221);
}

.user-type {
  margin-right: 10px;
  color: rgba(152, 2, 221, 1);
}

.merchandiser-number {
  margin-right: 14px;
  color: rgba(152, 2, 221, 1);
}

.question-1-no {
  margin-right: 10px;
  color: red;
}

.question-1-yes {
  margin-right: 10px;
  color: green;
}
.question-2-no {
  margin-right: 10px;
  color: red;
}

.question-2-yes {
  margin-right: 10px;
  color: green;
}

.filter-type-header {
  padding-top: 10px;
  font-size: 15px;
  font-weight: 600;
}

.call-filter-icon {
  margin-right: 5px;
  color: #d12673;
}
.ant-calendar-footer {
  padding: 0px;
}
.ant-calendar-range .ant-calendar-footer-extra {
  float: none;
}

.login-username input {
  border: none;
  border-bottom: 1px solid rgba(122, 122, 122, 0.6);
  border-radius: 0px;
}

.login-password input {
  border: none;
  border-bottom: 1px solid rgba(122, 122, 122, 0.6);
  border-radius: 0px;
}

.image-url-search-conatiner {
  padding: 25px 50px 25px 50px;
}

.image-url-search {
  padding: 5px;
}

.canvas-list-conatiner {
  text-align: center;
  padding: 50px;
  background: #f0f0f1;
}

.image-recog-list .ant-list-header {
  background: #3c3b54;
}

.image-recog-list .ant-list-header h3 {
  color: white;
}
.back-button {
  padding: 0px;
  float: right;
}

.upload-file-container {
  padding: 30px;
}

.error-list {
  background: white;
  color: red;
}

.upload-route-plan-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.route-plan-btn {
  color: white;
  background-color: rgba(246, 0, 131, 1);
  border-radius: 50%;
  font-size: 24px;
  height: 50px;
  width: 50px;
  padding-top: 0px;
}

.upload-file-container {
  background: rgba(97, 95, 145, 0.2);
  text-align: center;
  padding-top: 35px;
}

.upload-file-container .ant-form {
  padding-top: 15px;
}

.upload-route-plan-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

/* canvas{
  height: 100px;
  width: 100px;
} */

.pdf-preview {
  height: 200px;
}

.latest-call-image-modal .ant-modal-body {
  background: none;
  background: rgba(0, 0, 0, 0);
  padding: 0px;
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 5px rgba(97, 95, 145, 0.2);
  }
  50% {
    box-shadow: 0 0 0 25px rgba(97, 95, 145, 0.1);
  }
  100% {
    box-shadow: 0 0 0 45px rgba(97, 95, 145, 0);
  }
}
.overlay_tag {
  position: absolute;
  z-index: 54;
  right: 10px;
  background: #ffffff9c;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  top: 10px;
  border: 2px solid #e4418a;
}

.call-modal-display {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.call-modal-display-child {
  flex: 33%;
  margin: 12px 0px 18px 0px;
}

.call-modal-personal-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: black;
  display: flex;
  align-items: center;
}

.call-modal-personal-title > *:first-child {
  margin-right: 5px;
}

.call-modal-personal-value {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #231f20;
  margin-top: 2px;
}

.call-modal-personal-detail {
  height: 400px;
  width: 65%;
  margin: 8px;
  padding: 20px 0px 0px 30px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 12px;
  font-family: "Source Sans Pro";
  font-size: 22px;
  font-weight: 400;
  color: #333333;
  background-color: white;
}
.call-modal-image {
  height: 400px;
  width: 35%;
  margin: 8px;
  padding: 4px 0px 0px 0px;
  box-sizing: border-box;
  border-radius: 12px;
}
.call-modal-image img {
  border-radius: 10px;
  padding: 0px;
}

.call-details-carousal-image {
  width: 100%;
  border-radius: 12px;
}

.verticle-line {
  width: 1px;
  border-right: 1px dashed #28242478;
}

.validation-info-section {
  width: 32.5%;
  padding: 16px;
}

.button-submit-validation,
.button-submit-validation:focus {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Source Sans Pro";
  font-weight: 600;
  color: white;
  border: 1px solid #e4418a;
  background: #e4418a;
  font-size: 15px;
  margin: 8px 8px 0px 0px;
  min-width: 120px;
  width: calc(50% - 16px);
}

.button-submit-validation:hover {
  background: #e32b7ba6;
  color: white;
  border: none;
}

.button-submit-validation-green,
.button-submit-validation-green:focus {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Source Sans Pro";
  font-weight: 600;
  color: white;
  border: 1px solid #017d3b;
  background: #017d3b;
  font-size: 15px;
  min-width: 120px;
  margin: 8px 8px 0px 0px;
  width: calc(50% - 16px);
}

.button-submit-validation-green:hover {
  background: #006335ae;
  color: white;
  border: none;
}

.content-validation {
  padding: 50px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.modal-btn-val {
  width: 120px !important;
}
