@import "~antd/dist/antd.css";

.App {
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-card-grid {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-card-head {
  border: 0px;
}
.ant-card-actions {
  border: 0px;
}

.ant-tabs-bar{
  margin:0px
}

.slick-list {
  border-radius: 12px;
}

.ant-modal-content {
  background: #fafafa;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fdf0f6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e32b7c7a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-spin.ant-spin-show-text .ant-spin-text {
  font-weight: 300;
}

.ant-spin-dot-item {
  background-color: #e2136e;
}

.ant-layout-sider-children {
  background: #fafafa;
  box-shadow: 4px 4px 12px 0px rgba(54, 43, 115, 0.08);
  position: relative;
}

.ant-drawer-body {
  padding-top: 0;
  font-family: "Roboto Flex";
}
.ant-menu-root.ant-menu-inline {
  background: #fafafa;
}

.ant-breadcrumb {
  font-size: 12px;
  font-family: "Inter";
}

.ant-tabs .ant-tabs-left-content {
  padding-left: 0;
}
