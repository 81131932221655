@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap");

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",  "Source Sans Pro"
    monospace;
} */

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600;800&family=Roboto+Flex:opsz,wght@8..144,400;8..144,600&family=Source+Sans+Pro:ital,wght@0,400;0,600;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    "Source Sans Pro" monospace;
}

.route-plan-popup {
}

.ant-tabs-right {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.ant-tabs-right-content {
  background: #3c3b5456 !important;
  padding: 0px !important;
  overflow: auto !important;
  height: 100%;
  width: 250px !important;
}

.ant-collapse-borderless {
  background: #3c3b54 !important;
}

.ant-collapse-header {
  background: #3c3b54e3;
  color: white !important;
}

.ant-collapse-content-box {
  background: #f5f5f556 !important;
  color: #3c3b54;
  font-weight: 500;
}

.ant-tabs-right-bar {
  width: 10px;
}

.ant-table-fixed {
  white-space: nowrap;
}

.ant-table-row {
  white-space: nowrap;
}

.ant-layout-content {
  background: #f2f2f2;
}
