.loading {
  text-align: center;
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(255, 5, 180, 0.3);
  border-radius: 50%;
  border-top-color: rgb(255, 5, 180);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
