.privacy-data {
  padding: 50px;
}

.privacy-data strong {
  font-size: 25px;
}
.privacy-data p {
  font-size: 16px;
}
