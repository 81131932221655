table thead tr .Outlet{
    background: #68677d !important;
    /* color: white !important; */
}



table thead tr .outlet_not_found{
    background: #68677d !important;
    /* color: white !important; */
}
table thead tr .found_open{
    background: #68677d !important;
    /* color: white !important; */
}
table thead tr .found_closed{
    background: #68677d !important;
    /* color: white !important; */
}





table thead tr .Campaign{
    background: #68677d !important;
    /* color: white !important; */
}



table thead tr .Effective_CallTarget_campaign{
    background: #68677d !important;
    /* color: white !important; */
}
table thead tr .Actual_CallTarget_campaign{
    background: #68677d !important;
    /* color: white !important; */
}
table thead tr .Achievement_CallTarget_campaign{
    background: #68677d !important;
    /* color: white !important; */
}





table thead tr .Regular{
    background: #68677d !important;
    /* color: white !important; */
}

table thead tr .Effective_CallTarget_regular{
    background: #68677d !important;
    /* color: white !important; */
}
table thead tr .Actual_CallTarget_regular{
    background: #68677d !important;
    /* color: white !important; */
}
table thead tr .Achievement_CallTarget_regular{
    background: #68677d !important;
    /* color: white !important; */
}




table thead tr .Outlet_Call{
    background: #68677d !important;
    /* color: white !important; */
}

table thead tr .Effective_CallTarget_specific_outlet_call{
    background: #68677d !important;
    /* color: white !important; */
}
table thead tr .Actual_CallTarget_specific_outlet_call{
    background: #68677d !important;
    /* color: white !important; */
}
table thead tr .Achievement_CallTarget_specific_outlet_call{
    background: #68677d !important;
    /* color: white !important; */
}