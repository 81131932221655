@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600;800&family=Roboto+Flex:opsz,wght@8..144,400;8..144,600&family=Source+Sans+Pro:ital,wght@0,400;0,600;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Flex&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);
body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",  "Source Sans Pro"
    monospace;
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    "Source Sans Pro" monospace;
}

.route-plan-popup {
}

.ant-tabs-right {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.ant-tabs-right-content {
  background: #3c3b5456 !important;
  padding: 0px !important;
  overflow: auto !important;
  height: 100%;
  width: 250px !important;
}

.ant-collapse-borderless {
  background: #3c3b54 !important;
}

.ant-collapse-header {
  background: #3c3b54e3;
  color: white !important;
}

.ant-collapse-content-box {
  background: #f5f5f556 !important;
  color: #3c3b54;
  font-weight: 500;
}

.ant-tabs-right-bar {
  width: 10px;
}

.ant-table-fixed {
  white-space: nowrap;
}

.ant-table-row {
  white-space: nowrap;
}

.ant-layout-content {
  background: #f2f2f2;
}

.App {
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ant-card-grid {
  box-shadow: none;
}

.ant-card-head {
  border: 0px;
}
.ant-card-actions {
  border: 0px;
}

.ant-tabs-bar{
  margin:0px
}

.slick-list {
  border-radius: 12px;
}

.ant-modal-content {
  background: #fafafa;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fdf0f6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e32b7c7a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-spin.ant-spin-show-text .ant-spin-text {
  font-weight: 300;
}

.ant-spin-dot-item {
  background-color: #e2136e;
}

.ant-layout-sider-children {
  background: #fafafa;
  box-shadow: 4px 4px 12px 0px rgba(54, 43, 115, 0.08);
  position: relative;
}

.ant-drawer-body {
  padding-top: 0;
  font-family: "Roboto Flex";
}
.ant-menu-root.ant-menu-inline {
  background: #fafafa;
}

.ant-breadcrumb {
  font-size: 12px;
  font-family: "Inter";
}

.ant-tabs .ant-tabs-left-content {
  padding-left: 0;
}

/* .my-icon-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.test {
  border: 1px solid red;
}

.menu-link-name {
  font-family: "Roboto Flex", sans-serif;
  color: rgba(54, 43, 115, 1);
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: transparent;
}

.sider-container .ant-layout-sider-children {
  background: #3c3b54;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inventory-noti {
  position: absolute;
  z-index: 45;
  top: 54%;
  transform: translate(0, -50%);
  right: 35%;
  background-color: #e2136e;
  margin: 0px;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
}

.faIcons {
  color: rgba(54, 43, 115, 1);
}

.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark
  .ant-menu-submenu-open
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after,
.ant-menu-dark
  .ant-menu-submenu-open
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before,
.ant-menu-dark
  .ant-menu-submenu-open
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::after,
.ant-menu-dark
  .ant-menu-submenu-open
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::before,
.ant-menu-dark
  .ant-menu-submenu-active
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before,
.ant-menu-dark
  .ant-menu-submenu-active
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after {
  background: rgba(54, 43, 115, 1);
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #e7dddd;
  box-shadow: none;
} */

.ant-menu-item > a:hover,
.ant-menu-submenu-title:hover {
  color: #e4418a;
}

.ant-menu-item-selected > a {
  color: #e4418a;
}

.ant-menu-submenu-selected {
  color: #e4418a;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(226, 19, 110, 0.1);
}

.ant-menu-inline .ant-menu-item::after {
  border-right: none;
  border-radius: 20px;
}

.ant-menu-root.ant-menu-inline {
  font-family: "Roboto Flex", sans-serif;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  padding-top: 40px;
  background: #fafafa;
  border-right: none;
}

.ant-menu-inline .ant-menu-item {
  width: 178px;
  margin: 0px;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}

.ant-menu-inline .ant-menu-submenu-title {
  width: 178px;
  margin: 0px;
}

.ant-menu-item {
  color: rgba(54, 43, 115, 1);
}

.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after {
  background: #e4418a;
}

.ant-menu-inline-collapsed {
  padding-top: 40px;
  width: 80px;
}

.loading {
  text-align: center;
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(255, 5, 180, 0.3);
  border-radius: 50%;
  border-top-color: rgb(255, 5, 180);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.simple-table .ant-table {
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.2);
}

.simple-table .ant-table .ant-table-thead tr th {
  background-color: #43425d;
  color: white;
  font-weight: 600;
  font-size: 15px;
}

.simple-table .numeric {
  text-align: right;
}

.simple-table tr {
  background-color: white;
}

.simple-table .ant-table-row:hover {
  background-color: rgba(97, 95, 145, 0.3);
}

.simple-table .ant-table-row:active {
  background-color: rgba(97, 95, 145, 0.3);
}

.simple-table .ant-table-pagination li {
  border: 1px solid rgba(97, 95, 145, 0.9);
}

.simple-table a {
  color: rgba(97, 95, 145, 0.9);
}

.ant-table-filter-dropdown-btns {
  display: none;
}

table thead tr .Outlet{
    background: #68677d !important;
    /* color: white !important; */
}



table thead tr .outlet_not_found{
    background: #68677d !important;
    /* color: white !important; */
}
table thead tr .found_open{
    background: #68677d !important;
    /* color: white !important; */
}
table thead tr .found_closed{
    background: #68677d !important;
    /* color: white !important; */
}





table thead tr .Campaign{
    background: #68677d !important;
    /* color: white !important; */
}



table thead tr .Effective_CallTarget_campaign{
    background: #68677d !important;
    /* color: white !important; */
}
table thead tr .Actual_CallTarget_campaign{
    background: #68677d !important;
    /* color: white !important; */
}
table thead tr .Achievement_CallTarget_campaign{
    background: #68677d !important;
    /* color: white !important; */
}





table thead tr .Regular{
    background: #68677d !important;
    /* color: white !important; */
}

table thead tr .Effective_CallTarget_regular{
    background: #68677d !important;
    /* color: white !important; */
}
table thead tr .Actual_CallTarget_regular{
    background: #68677d !important;
    /* color: white !important; */
}
table thead tr .Achievement_CallTarget_regular{
    background: #68677d !important;
    /* color: white !important; */
}




table thead tr .Outlet_Call{
    background: #68677d !important;
    /* color: white !important; */
}

table thead tr .Effective_CallTarget_specific_outlet_call{
    background: #68677d !important;
    /* color: white !important; */
}
table thead tr .Actual_CallTarget_specific_outlet_call{
    background: #68677d !important;
    /* color: white !important; */
}
table thead tr .Achievement_CallTarget_specific_outlet_call{
    background: #68677d !important;
    /* color: white !important; */
}
.web-footer-container {
  margin: 0px 0;
  display: flex;
  text-align: center;
  justify-content: center; }
  .web-footer-container p {
    color: #acacac; }

/* .sider-container .ant-menu-inline .ant-menu-item {
  margin-bottom: 0px;
  margin-top: 0px;

  z-index: 30;
}

.ant-menu-dark.ant-menu-submenu-popup {
  background: #000000e3;
}
.sider-container .ant-menu-inline .ant-menu-submenu {
  margin-bottom: 0px;
  margin-top: 0px;
  background: rgba(250, 250, 250, 1);
  z-index: 30;
}

.sider-container .ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.sider-container .ant-menu-inline .ant-menu-item-selected {
  background: rgb(226 19 110 / 12%);
  
  border-radius: 32px;
  
}

.sider-container .ant-menu-inline-collapsed .ant-menu-item-selected {
  background: #3c3b54;
  border-left: 2px #ff4d9c solid;
}

.sider-container .ant-menu-inline .ant-menu-item a {
  font-size: 14px;
}

.sider-container .ant-menu-inline .ant-menu-item a .faIcons {
  font-size: 16px;
}

.sider-container .ant-menu-inline .ant-menu-item-selected a {
  color: #ff4d9c;
}

.sider-container .ant-layout-sider-children {
  background: rgba(250, 250, 250, 1);
  padding-left: 20px;
  padding-right: 20px;
}

.sider-container .ant-layout-sider-zero-width-trigger {
  z-index: 30;
} */

.sider-container {
  background: transparent;
  box-shadow: -3px -4px 20px 0px rgba(54, 43, 115, 0.08);
}

.hide-button-nav {
  cursor: pointer;
  text-align: center;
  /* color: white; */
  font-weight: 700;
  font-family: monospace;
  font-size: 20px;
  opacity: 0.5;
  background: #fafafa;
  border: none;
  position: absolute;
  bottom: 25px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 10;
}

.hide-button-nav:hover,
.hide-button-nav:focus {
  color: rgb(240, 78, 147);
  background: #fafafa;
}

.ant-menu-inline-collapsed {
  padding-top: 40px;
  width: 80px;
}

.hidden-nav {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.show-nav {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.version-biponon {
  font-family: monospace;
  font-size: 10px;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
  color: #e32a7c;
}

.header {
  background: white;
  box-shadow: 1px 1px 1px 2px #2222;
  position: relative;
  z-index: 10;
}

.header-navbar-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.header-navbar-logo {
  float: left;
  padding: 15px 0px 15px 32px;
}

.header-navbar-logout {
  float: right;
  padding: 20px 32px 20px 0px;
}

.header-navbar-logout button {
  border: 0px;
  background: white;
  box-shadow: none;
  font-weight: 500;
  font-size: 13px;
  padding: 4px;
  margin-top: -2px;
  cursor: pointer;
  text-decoration: underline;
}

.header-navbar-logout button :hover {
  color: black;
}

.header-navbar-refresh {
  float: right;
  padding: 15px 20px 15px 15px;
}

.login-form-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-image {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

.login-image img {
  width: 250px;
}

.login-form-card {
  width: 450px;
  padding: 0px;
  border: 0px;
}

.login-submit-button {
  width: 100%;
}

.title-container {
}

.dash-user-card {
  border-radius: 5px;
  box-shadow: 1px 1px 1px 0px rgba(97, 95, 145, 0.3);
}
.dash-user-card .ant-card-head {
  background: rgba(97, 95, 145, 0.5);
  color: rgba(60, 59, 84, 1);
}
.user-card-container {
  padding: 30px;
  background: #f6f6f6;
}

.outlet-card-container {
  padding: 30px;
  background: #f0f0f1;
}

.card-triple-image-container-body {
}
.table-container {
  padding: 30px;
  background-color: #f2f2f2;
}

.title-search-conatiner {
  padding: 30px 30px 0 30px;
}

.map-container {
  padding: 0 30px;
}

.map-container .markercluster-map {
  box-shadow: 1px 1px 1px 1px #2222;
}

.map-card .ant-card-body {
  padding: 0px;
}

.search-input input {
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid #43425d;
  background: white;
}

.search-input input:hover {
  box-shadow: none;
  border-radius: 0px;
}

.search-input input:focus {
  box-shadow: none;
  border-radius: 0px;
}

.search-input .ant-input:not(:first-child) {
  padding-left: 50px;
  font-size: 14px;
}
.search-input .ant-input-prefix {
  font-weight: 700;
}

.marker-card-side-line .marker-card-side-line-tag .line-tag {
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
}

.marker-card-side-line .marker-card-side-line-info .line-info {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

.leaflet-popup-content {
  margin: 0px;
}

.leaflet-popup-content-wrapper {
  border-radius: 5px;
  padding: 0px;
  margin: 0px;
}

.route-search-popup .leaflet-popup-content-wrapper {
  border-radius: 5px;
}

.merchandiser-drawer-left-side-line {
  padding-bottom: 15px;
}

.title-datepicker-conatiner {
  padding-bottom: 15px;
}

.outlet-drawer-left-side-line {
  padding-bottom: 5px;
}
.call-card {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgba(97, 95, 145, 0.3);
  box-shadow: 0px 0px 3px 0px rgba(97, 95, 145, 0.3);
  background: white;
}

.main-call-card {
  border-radius: 8px;
  border: 1px solid #efefef;

  /* background: rgba(60, 59, 84, 0); */
  background: white;
  min-height: 440px;
  padding: 16px;
}

.card-body-address-container {
}

.card-body-image-container {
  padding: 4px 4px 0px 4px;
  box-shadow: none;
}

.card-body-image-container:hover {
  box-shadow: none;
}

.ant-card-actions {
  background: none;
}

.ant-card-type-inner .ant-card-head {
  background-color: rgba(60, 59, 84, 1);
  color: white;
}
.ant-card-body {
  padding: 24px;
}

.call-card-line {
  padding-bottom: 10px;
}

.call-card-line .call-card-line-tag .line-tag {
  line-height: 1;
  color: rgba(60, 59, 84, 0.9);
}

.call-card-line .call-card-line-info .line-info {
  line-height: 1;
  color: rgba(60, 59, 84, 1);
}

.name icon {
  padding-right: 10px;
}

.name {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 6px;
  align-items: center;
}

.number {
  padding-right: 10px;
}

.upload-date i {
  padding: 5px;
}

.upload-date {
  font-size: 14px;
  font-weight: 400;
  color: #096ab9;
}

.upload-time i {
  padding: 5px;
  color: #096ab9;
}

.upload-time {
  font-size: 14px;
  font-weight: 400;
  color: #096ab9;
  margin-right: 14px;
}

.call-date i {
  padding: 5px;
}

.call-date {
  font-size: 14px;
  font-weight: 400;
  margin-right: 14px;
  color: #138f0d;
}

.call-time i {
  padding: 5px;
}

.call-time {
  font-size: 14px;
  font-weight: 400;
  margin-right: 14px;
  color: #138f0d;
}

.line-tag {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
}

.line-info {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
}

.ant-dropdown-menu-item:hover {
  background: rgba(97, 95, 145, 0.3);
  color: #3c3b54;
}
.ant-dropdown-menu-item-selected {
  background: rgba(97, 95, 145, 0.3);
  color: #3c3b54;
}

.submit-button {
  background: #e4418a;
  border: 1px solid #d12673;
  outline: none;
  color: white;
  font-weight: 500;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.3);
  margin-right: 5px;
}

.submit-button a svg {
  top: 23%;
  left: 28%;
}

.submit-button:hover {
  background: rgba(97, 95, 145, 0.3);
  border: none;
  outline: none;
  color: #3c3b54;
  font-weight: 500;
}

.submit-button:active {
  background: rgba(97, 95, 145, 0.3);
  border: none;
  outline: none;
  color: #3c3b54;
  font-weight: 500;
}

.submit-button:focus {
  background: rgba(97, 95, 145, 0.3);
  border: none;
  outline: none;
  color: #3c3b54;
  font-weight: 500;
}

.filter-container {
  padding: 8px;
}

.filter-collapse-button {
  width: 100%;
  /* background: rgba(217, 130, 159, 0.272); */
}

.filter-collapse-button > span {
  font-size: 14px;
  color: black;
  font-weight: 600;
}

.call-filter-ex-icon {
  color: #d12673;
  margin-left: 12px;
}

.shadow-container {
  background: rgba(217, 130, 159, 0.272);
  border-radius: 8px;
  border: 2px solid lightgray;
}

.filter {
  padding: 10px 16px 10px 16px;
}

.elastic-select .ant-select-selection:hover {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.elastic-select .ant-select-selection:active {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.elastic-select .ant-select-selection:focus {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.elastic-select:active {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.responsive-image {
  position: relative;
  max-width: 100%;
  margin-bottom: 5px;
  border-radius: 50%;
}

.responsive-image__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  box-shadow: 1px 1px 1px 0px rgba(97, 95, 145, 0.1);
  border-radius: 5px;
}

.responsive-image__image:hover {
  border: 1px solid #e4418a;
}

.profile-image {
  position: relative;
  max-width: 100%;
  margin-bottom: 5px;
  border-radius: 50%;
}
.profile-image__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  box-shadow: 1px 1px 1px 0px rgba(97, 95, 145, 0.1);
  border-radius: 50%;
}

.latest-call-item .ant-list-item {
  padding-bottom: 6px;
}

.pre-call-card {
  background: rgba(190, 195, 214, 0.171);
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 3px 0px rgba(97, 95, 145, 0.3);
  color: rgba(0, 0, 0, 01);
  font-weight: 500;
  padding: 30px;
}

.pre-merchandiser-card {
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 3px 0px rgba(97, 95, 145, 0.3);
  color: rgba(0, 0, 0, 01);
  font-weight: 500;
  padding: 30px;
}

.agent-card {
  background: rgba(146, 144, 144, 0.5);
}

.merchant-card {
  background: rgba(255, 166, 0, 0.5);
}

.leaflet-marker-icon {
  opacity: 1;
}

.leaflet-div-icon {
  -webkit-animation: shadow-pulse 0.8s infinite;
          animation: shadow-pulse 0.8s infinite;
  background: none;
  border: none;
}

.profile-container {
  padding: 30px;
}

.profile-basic-info-row {
  padding-bottom: 0px;
}

.profile-basic-info-card {
  box-shadow: 0px 0px 1px 0px rgba(97, 95, 145, 0.3);
  font-weight: 500;
  color: rgba(0, 0, 0, 0.616);
  border-radius: 5px;
}
.profile-selfie-card {
  box-shadow: 0px 0px 1px 0px rgba(97, 95, 145, 0.3);
  font-weight: 500;
  color: rgba(0, 0, 0, 0.616);
  border-radius: 5px;
}

.profile-basic-info-line {
  line-height: 1.5;
}

.info-line {
  text-align: center;
}

.profile-image-line {
  text-align: center;
  padding: 10px 0 10px 0;
}

.profile-image {
  padding: 15px;
}

.profile-selfie-card {
  padding-top: 16px;
}

.form-date-elasticsearch {
  padding: 30px 30px 50px 30px;
}

.form-date-range-elasticsearch {
  padding: 30px 30px 50px 0px;
}

.ant-select-selection:focus {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.ant-select-selection {
  box-shadow: none;
}

.ant-select-selection:hover {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.ant-select-selection:active {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.ant-select-selection:focus {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.ant-calendar-picker :focus {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.ant-calendar-picker input:hover {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.ant-calendar-picker input:active {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.ant-calendar-picker input:focus {
  box-shadow: 0 0 0 2px rgba(97, 95, 145, 0.3);
}

.ant-select-dropdown-menu li:hover {
  background: rgba(97, 95, 145, 0.3);
}

.alert-container {
  padding: 50px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: rgba(97, 95, 145, 0.8);
}

.report-icon {
  color: rgb(0, 165, 0);
  margin-right: 5px;
  font-size: 25px;
}

.modal-icon {
  color: rgb(5, 5, 5);
  font-size: 18px;
}

.outlet-name {
  margin-right: 9px;
  color: rgb(255, 5, 180);
}
.partner-type {
  margin-right: 6px;
  color: rgba(255, 5, 180, 1);
}
.outlet-number {
  margin-right: 14px;
  color: rgba(255, 5, 180, 1);
}

.outlet-region {
  margin-right: 10px;
  color: transparent;
  text-shadow: 0 0 0 rgba(8, 105, 185, 0.8);
}

.outlet-area {
  margin-right: 10px;
  color: transparent;
  text-shadow: 0 0 0 rgba(8, 105, 185, 0.6);
}

.outlet-territory {
  margin-right: 10px;
  color: transparent;
  text-shadow: 0 0 0 rgba(8, 105, 185, 0.4);
}

.outlet-distribution-house {
  margin-right: 10px;
  color: transparent;
  text-shadow: 0 0 0 rgba(8, 105, 185, 0.2);
}

.outlet-bmcc {
  margin-right: 10px;
  color: transparent;
  text-shadow: 0 0 0 rgba(8, 105, 185, 0.1);
}

.outlet-address {
  margin-right: 6px;
  color: #096ab9;
}
.outlet-qrcode {
  margin-left: 5px;
  margin-right: 12px;
  color: rgb(2, 2, 2);
}

.outlet-lat {
  margin-right: 10px;
  color: rgba(255, 0, 0, 1);
}

.outlet-lng {
  margin-right: 10px;
  color: rgba(255, 0, 0, 1);
}
.user-name {
  margin-right: 10px;
  color: rgb(152, 2, 221);
}

.user-type {
  margin-right: 10px;
  color: rgba(152, 2, 221, 1);
}

.merchandiser-number {
  margin-right: 14px;
  color: rgba(152, 2, 221, 1);
}

.question-1-no {
  margin-right: 10px;
  color: red;
}

.question-1-yes {
  margin-right: 10px;
  color: green;
}
.question-2-no {
  margin-right: 10px;
  color: red;
}

.question-2-yes {
  margin-right: 10px;
  color: green;
}

.filter-type-header {
  padding-top: 10px;
  font-size: 15px;
  font-weight: 600;
}

.call-filter-icon {
  margin-right: 5px;
  color: #d12673;
}
.ant-calendar-footer {
  padding: 0px;
}
.ant-calendar-range .ant-calendar-footer-extra {
  float: none;
}

.login-username input {
  border: none;
  border-bottom: 1px solid rgba(122, 122, 122, 0.6);
  border-radius: 0px;
}

.login-password input {
  border: none;
  border-bottom: 1px solid rgba(122, 122, 122, 0.6);
  border-radius: 0px;
}

.image-url-search-conatiner {
  padding: 25px 50px 25px 50px;
}

.image-url-search {
  padding: 5px;
}

.canvas-list-conatiner {
  text-align: center;
  padding: 50px;
  background: #f0f0f1;
}

.image-recog-list .ant-list-header {
  background: #3c3b54;
}

.image-recog-list .ant-list-header h3 {
  color: white;
}
.back-button {
  padding: 0px;
  float: right;
}

.upload-file-container {
  padding: 30px;
}

.error-list {
  background: white;
  color: red;
}

.upload-route-plan-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.route-plan-btn {
  color: white;
  background-color: rgba(246, 0, 131, 1);
  border-radius: 50%;
  font-size: 24px;
  height: 50px;
  width: 50px;
  padding-top: 0px;
}

.upload-file-container {
  background: rgba(97, 95, 145, 0.2);
  text-align: center;
  padding-top: 35px;
}

.upload-file-container .ant-form {
  padding-top: 15px;
}

.upload-route-plan-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

/* canvas{
  height: 100px;
  width: 100px;
} */

.pdf-preview {
  height: 200px;
}

.latest-call-image-modal .ant-modal-body {
  background: none;
  background: rgba(0, 0, 0, 0);
  padding: 0px;
}
@-webkit-keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 5px rgba(97, 95, 145, 0.2);
  }
  50% {
    box-shadow: 0 0 0 25px rgba(97, 95, 145, 0.1);
  }
  100% {
    box-shadow: 0 0 0 45px rgba(97, 95, 145, 0);
  }
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 5px rgba(97, 95, 145, 0.2);
  }
  50% {
    box-shadow: 0 0 0 25px rgba(97, 95, 145, 0.1);
  }
  100% {
    box-shadow: 0 0 0 45px rgba(97, 95, 145, 0);
  }
}
.overlay_tag {
  position: absolute;
  z-index: 54;
  right: 10px;
  background: #ffffff9c;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  top: 10px;
  border: 2px solid #e4418a;
}

.call-modal-display {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.call-modal-display-child {
  flex: 33% 1;
  margin: 12px 0px 18px 0px;
}

.call-modal-personal-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: black;
  display: flex;
  align-items: center;
}

.call-modal-personal-title > *:first-child {
  margin-right: 5px;
}

.call-modal-personal-value {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #231f20;
  margin-top: 2px;
}

.call-modal-personal-detail {
  height: 400px;
  width: 65%;
  margin: 8px;
  padding: 20px 0px 0px 30px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 12px;
  font-family: "Source Sans Pro";
  font-size: 22px;
  font-weight: 400;
  color: #333333;
  background-color: white;
}
.call-modal-image {
  height: 400px;
  width: 35%;
  margin: 8px;
  padding: 4px 0px 0px 0px;
  box-sizing: border-box;
  border-radius: 12px;
}
.call-modal-image img {
  border-radius: 10px;
  padding: 0px;
}

.call-details-carousal-image {
  width: 100%;
  border-radius: 12px;
}

.verticle-line {
  width: 1px;
  border-right: 1px dashed #28242478;
}

.validation-info-section {
  width: 32.5%;
  padding: 16px;
}

.button-submit-validation,
.button-submit-validation:focus {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Source Sans Pro";
  font-weight: 600;
  color: white;
  border: 1px solid #e4418a;
  background: #e4418a;
  font-size: 15px;
  margin: 8px 8px 0px 0px;
  min-width: 120px;
  width: calc(50% - 16px);
}

.button-submit-validation:hover {
  background: #e32b7ba6;
  color: white;
  border: none;
}

.button-submit-validation-green,
.button-submit-validation-green:focus {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Source Sans Pro";
  font-weight: 600;
  color: white;
  border: 1px solid #017d3b;
  background: #017d3b;
  font-size: 15px;
  min-width: 120px;
  margin: 8px 8px 0px 0px;
  width: calc(50% - 16px);
}

.button-submit-validation-green:hover {
  background: #006335ae;
  color: white;
  border: none;
}

.content-validation {
  padding: 50px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.modal-btn-val {
  width: 120px !important;
}

.privacy-data {
  padding: 50px;
}

.privacy-data strong {
  font-size: 25px;
}
.privacy-data p {
  font-size: 16px;
}

