.header {
  background: white;
  box-shadow: 1px 1px 1px 2px #2222;
  position: relative;
  z-index: 10;
}

.header-navbar-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.header-navbar-logo {
  float: left;
  padding: 15px 0px 15px 32px;
}

.header-navbar-logout {
  float: right;
  padding: 20px 32px 20px 0px;
}

.header-navbar-logout button {
  border: 0px;
  background: white;
  box-shadow: none;
  font-weight: 500;
  font-size: 13px;
  padding: 4px;
  margin-top: -2px;
  cursor: pointer;
  text-decoration: underline;
}

.header-navbar-logout button :hover {
  color: black;
}

.header-navbar-refresh {
  float: right;
  padding: 15px 20px 15px 15px;
}
