.simple-table .ant-table {
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.2);
}

.simple-table .ant-table .ant-table-thead tr th {
  background-color: #43425d;
  color: white;
  font-weight: 600;
  font-size: 15px;
}

.simple-table .numeric {
  text-align: right;
}

.simple-table tr {
  background-color: white;
}

.simple-table .ant-table-row:hover {
  background-color: rgba(97, 95, 145, 0.3);
}

.simple-table .ant-table-row:active {
  background-color: rgba(97, 95, 145, 0.3);
}

.simple-table .ant-table-pagination li {
  border: 1px solid rgba(97, 95, 145, 0.9);
}

.simple-table a {
  color: rgba(97, 95, 145, 0.9);
}

.ant-table-filter-dropdown-btns {
  display: none;
}
