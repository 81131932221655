/* .sider-container .ant-menu-inline .ant-menu-item {
  margin-bottom: 0px;
  margin-top: 0px;

  z-index: 30;
}

.ant-menu-dark.ant-menu-submenu-popup {
  background: #000000e3;
}
.sider-container .ant-menu-inline .ant-menu-submenu {
  margin-bottom: 0px;
  margin-top: 0px;
  background: rgba(250, 250, 250, 1);
  z-index: 30;
}

.sider-container .ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.sider-container .ant-menu-inline .ant-menu-item-selected {
  background: rgb(226 19 110 / 12%);
  
  border-radius: 32px;
  
}

.sider-container .ant-menu-inline-collapsed .ant-menu-item-selected {
  background: #3c3b54;
  border-left: 2px #ff4d9c solid;
}

.sider-container .ant-menu-inline .ant-menu-item a {
  font-size: 14px;
}

.sider-container .ant-menu-inline .ant-menu-item a .faIcons {
  font-size: 16px;
}

.sider-container .ant-menu-inline .ant-menu-item-selected a {
  color: #ff4d9c;
}

.sider-container .ant-layout-sider-children {
  background: rgba(250, 250, 250, 1);
  padding-left: 20px;
  padding-right: 20px;
}

.sider-container .ant-layout-sider-zero-width-trigger {
  z-index: 30;
} */

.sider-container {
  background: transparent;
  box-shadow: -3px -4px 20px 0px rgba(54, 43, 115, 0.08);
}

.hide-button-nav {
  cursor: pointer;
  text-align: center;
  /* color: white; */
  font-weight: 700;
  font-family: monospace;
  font-size: 20px;
  opacity: 0.5;
  background: #fafafa;
  border: none;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.hide-button-nav:hover,
.hide-button-nav:focus {
  color: rgb(240, 78, 147);
  background: #fafafa;
}

.ant-menu-inline-collapsed {
  padding-top: 40px;
  width: 80px;
}

.hidden-nav {
  transform: rotate(0deg);
  transition: transform 0.5s;
}

.show-nav {
  transform: rotate(180deg);
  transition: transform 0.5s;
}

.version-biponon {
  font-family: monospace;
  font-size: 10px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0px);
  color: #e32a7c;
}
