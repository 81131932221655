.web-footer-container {
  margin: 0px 0;
  display: flex;
  text-align: center;
  justify-content: center;

  p {
    color: #acacac;
  }
}
