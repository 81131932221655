/* .my-icon-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.test {
  border: 1px solid red;
}

.menu-link-name {
  font-family: "Roboto Flex", sans-serif;
  color: rgba(54, 43, 115, 1);
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: transparent;
}

.sider-container .ant-layout-sider-children {
  background: #3c3b54;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inventory-noti {
  position: absolute;
  z-index: 45;
  top: 54%;
  transform: translate(0, -50%);
  right: 35%;
  background-color: #e2136e;
  margin: 0px;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
}

.faIcons {
  color: rgba(54, 43, 115, 1);
}

.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark
  .ant-menu-submenu-open
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after,
.ant-menu-dark
  .ant-menu-submenu-open
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before,
.ant-menu-dark
  .ant-menu-submenu-open
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::after,
.ant-menu-dark
  .ant-menu-submenu-open
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::before,
.ant-menu-dark
  .ant-menu-submenu-active
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before,
.ant-menu-dark
  .ant-menu-submenu-active
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after {
  background: rgba(54, 43, 115, 1);
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #e7dddd;
  box-shadow: none;
} */

.ant-menu-item > a:hover,
.ant-menu-submenu-title:hover {
  color: #e4418a;
}

.ant-menu-item-selected > a {
  color: #e4418a;
}

.ant-menu-submenu-selected {
  color: #e4418a;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(226, 19, 110, 0.1);
}

.ant-menu-inline .ant-menu-item::after {
  border-right: none;
  border-radius: 20px;
}

.ant-menu-root.ant-menu-inline {
  font-family: "Roboto Flex", sans-serif;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  padding-top: 40px;
  background: #fafafa;
  border-right: none;
}

.ant-menu-inline .ant-menu-item {
  width: 178px;
  margin: 0px;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}

.ant-menu-inline .ant-menu-submenu-title {
  width: 178px;
  margin: 0px;
}

.ant-menu-item {
  color: rgba(54, 43, 115, 1);
}

.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after {
  background: #e4418a;
}

.ant-menu-inline-collapsed {
  padding-top: 40px;
  width: 80px;
}
